// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__footer--HdB62 {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  background-color: var(--white);\n  z-index: 66;\n}\n.s-module__footer--HdB62 .s-module__footerInner--AquA8 {\n  padding: 22px 24px;\n  display: flex;\n  justify-content: space-between;\n}\n.s-module__footer--HdB62 .s-module__footerInner--AquA8 .s-module__back--lyoim {\n  padding: 4px 15px;\n  color: var(--primary-color);\n  margin-left: 10px;\n  cursor: pointer;\n  font-weight: 500;\n}\n.s-module__footer--HdB62 .s-module__footerInner--AquA8 .s-module__skipBtn--rvUKV {\n  margin-right: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationConfigPage/components/Footer/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,WAAA;EACA,8BAAA;EACA,WAAA;AACJ;AANA;EAOQ,kBAAA;EACA,aAAA;EACA,8BAAA;AAER;AAXA;EAYY,iBAAA;EACA,2BAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAEZ;AAlBA;EAoBY,kBAAA;AACZ","sourcesContent":[".footer {\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    background-color: var(--white);\n    z-index: 66;\n    .footerInner {\n        padding: 22px 24px;\n        display: flex;\n        justify-content: space-between;\n\n        .back{\n            padding: 4px 15px;\n            color: var(--primary-color);\n            margin-left: 10px;\n            cursor: pointer;\n            font-weight: 500;\n        }\n\n        .skipBtn {\n            margin-right: 16px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "s-module__footer--HdB62",
	"footerInner": "s-module__footerInner--AquA8",
	"back": "s-module__back--lyoim",
	"skipBtn": "s-module__skipBtn--rvUKV"
};
export default ___CSS_LOADER_EXPORT___;
