// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__detailBox--WFhcE {\n  padding: 32px 0;\n  font-family: 'Inter';\n  font-style: normal;\n}\n.s-module__detailBox--WFhcE .s-module__block--uwaDi {\n  margin-bottom: 24px;\n}\n.s-module__detailBox--WFhcE .s-module__opsCardBox--QVwZF {\n  padding: 0 24px;\n}\n.s-module__detailBox--WFhcE .s-module__opsCardBox--QVwZF .s-module__cardInner--ep2wr {\n  padding: 16px 24px;\n  background: #FFFFFF;\n}\n.s-module__detailBox--WFhcE .s-module__opsCardBox--QVwZF .s-module__cardInner--ep2wr h3 {\n  margin-bottom: 8px;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 28px;\n  color: var(--gray-800);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/ChannelWithdrawDetailPage/components/Detail/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,oBAAA;EACA,kBAAA;AACJ;AAJA;EAKQ,mBAAA;AAER;AAPA;EAQQ,eAAA;AAER;AAVA;EAWY,kBAAA;EACA,mBAAA;AAEZ;AAdA;EAcgB,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAGhB","sourcesContent":[".detailBox {\n    padding: 32px 0;\n    font-family: 'Inter';\n    font-style: normal;\n    .block {\n        margin-bottom: 24px;\n    }\n    .opsCardBox {\n        padding: 0 24px;\n\n        .cardInner {\n            padding: 16px 24px;\n            background: #FFFFFF;\n            h3 {\n                margin-bottom: 8px;\n                font-weight: 500;\n                font-size: 20px;\n                line-height: 28px;\n                color: var(--gray-800);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailBox": "s-module__detailBox--WFhcE",
	"block": "s-module__block--uwaDi",
	"opsCardBox": "s-module__opsCardBox--QVwZF",
	"cardInner": "s-module__cardInner--ep2wr"
};
export default ___CSS_LOADER_EXPORT___;
