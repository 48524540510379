// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--Ivapx {\n  display: flex;\n  align-items: center;\n  position: fixed;\n  width: 100%;\n  left: 0;\n  bottom: 62px;\n  background-color: var(--white);\n}\n.s-module__wrap--Ivapx .s-module__bar--It3CX {\n  margin-right: 8px;\n  flex: 1;\n  height: 4px;\n  background-color: var(--gray-300);\n  border-radius: 10px;\n}\n.s-module__wrap--Ivapx .s-module__bar--It3CX:last-child {\n  margin-right: 0;\n}\n.s-module__wrap--Ivapx .s-module__bar--It3CX.s-module__actived--j73EP {\n  background-color: var(--main-green);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/EHROnboardingPage/components/ProgressBar/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,OAAA;EACA,YAAA;EACA,8BAAA;AACJ;AARA;EAUQ,iBAAA;EACA,OAAA;EACA,WAAA;EACA,iCAAA;EACA,mBAAA;AACR;AACQ;EACI,eAAA;AACZ;AAEQ;EACI,mCAAA;AAAZ","sourcesContent":[".wrap {\n    display: flex;\n    align-items: center;\n    position: fixed;\n    width: 100%;\n    left: 0;\n    bottom: 62px;\n    background-color: var(--white);\n\n    .bar {\n        margin-right: 8px;\n        flex: 1;\n        height: 4px;\n        background-color: var(--gray-300);\n        border-radius: 10px;\n\n        &:last-child {\n            margin-right: 0;\n        }\n\n        &.actived {\n            background-color: var(--main-green);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--Ivapx",
	"bar": "s-module__bar--It3CX",
	"actived": "s-module__actived--j73EP"
};
export default ___CSS_LOADER_EXPORT___;
