import React, { useState } from 'react';
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import s from './s.module.less';
import KiwiExpandYourReach from '../KiwiExpandYourReach';
import KiwiEnhancePatientExperience from '../KiwiEnhancePatientExperience';
import KiwiNewServiceOutreach from '../KiwiNewServiceOutreach';
import KlarityExpandYourReach from '../KlarityExpandYourReach';
import KlarityEnhancePatientExperience from '../KlarityEnhancePatientExperience';
import KlarityNewServiceOutreach from '../KlarityNewServiceOutreach';
import cx from 'classnames';
import HeadwayEntry from '../HeadwayEntry';

type IProps = {
    isKlarity: boolean;
};

const Index: React.FC<IProps> = ({ isKlarity }) => {
    const [activeKey, setActiveKey] = useState('1');
    const kiwiItems: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <span className={cx(s.tabItem, s.tabItem1)}>Expand your reach</span>
            ),
            children: (
                <>
                    <HeadwayEntry />
                    <KiwiExpandYourReach />
                </>
            ),
        },
        {
            key: '2',
            label: (
                <span className={cx(s.tabItem, s.tabItem2)}>
                    Enhance patient experience
                </span>
            ),
            children: <KiwiEnhancePatientExperience />,
        },
        {
            key: '3',
            label: (
                <span className={cx(s.tabItem, s.tabItem3)}>
                    New service outreach
                </span>
            ),
            children: <KiwiNewServiceOutreach />,
        },
    ];

    const klarityItems: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <span className={cx(s.tabItem, s.tabItem1)}>Expand your reach</span>
            ),
            children: (
                <KlarityExpandYourReach />
            ),
        },
        {
            key: '2',
            label: (
                <span className={cx(s.tabItem, s.tabItem2)}>
                    Enhance patient experience
                </span>
            ),
            children: <KlarityEnhancePatientExperience />,
        },
        {
            key: '3',
            label: (
                <span className={cx(s.tabItem, s.tabItem3)}>New service outreach</span>
            ),
            children: <KlarityNewServiceOutreach />,
        },
    ];

    const handleChangeTab = (key: string) => {
        setActiveKey(key);
    };

    return (
        <div className={s.wrap}>
            <h2 className={s.title}>Top opportunities</h2>
            <Tabs
                className={s.tabBorder}
                activeKey={activeKey}
                onChange={(currentKey) => handleChangeTab(currentKey)}
                items={isKlarity ? klarityItems : kiwiItems}
            />
        </div>
    );
};

export default Index;
