// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--EPtIv {\n  padding: 22px 0;\n  background-color: var(--white);\n  font-family: 'Inter';\n  font-style: normal;\n  border-radius: 16px;\n}\n.s-module__wrap--EPtIv .s-module__title--FQWhk {\n  color: var(--gray-800);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n  margin-bottom: 12px;\n}\n.s-module__wrap--EPtIv .s-module__btnWrap--WhbxE {\n  margin-top: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/ChannelWithdrawDetailPage/components/StatusCard/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,8BAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;AANA;EAQQ,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACR;AAdA;EAiBQ,gBAAA;AAAR","sourcesContent":[".wrap {\n    padding: 22px 0;\n    background-color: var(--white);\n    font-family: 'Inter';\n    font-style: normal;\n    border-radius: 16px;\n\n    .title {\n        color: var(--gray-800);\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 28px;\n        margin-bottom: 12px;\n    }\n\n    .btnWrap{\n        margin-top: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--EPtIv",
	"title": "s-module__title--FQWhk",
	"btnWrap": "s-module__btnWrap--WhbxE"
};
export default ___CSS_LOADER_EXPORT___;
