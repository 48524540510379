// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__buttonWrap--NZEX0 {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/KiwiInitStep3/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACF","sourcesContent":[".buttonWrap {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrap": "s-module__buttonWrap--NZEX0"
};
export default ___CSS_LOADER_EXPORT___;
