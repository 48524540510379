// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--xmM_f {\n  background-color: var(--main-bg-color);\n}\n.s-module__step--NTv_v {\n  display: flex;\n  position: fixed;\n  bottom: 76px;\n  left: 0;\n  width: 100%;\n  z-index: 80;\n}\n.s-module__step--NTv_v .s-module__stepBar--cfnQo {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  height: 4px;\n  border-radius: 10px;\n  background: var(--gray-300, #D1D5DB);\n}\n.s-module__step--NTv_v .s-module__stepBar--cfnQo.s-module__wrapBar--rPYoQ {\n  margin-right: 16px;\n}\n.s-module__step--NTv_v .s-module__stepBar--cfnQo.s-module__wrapBar--rPYoQ:last-child {\n  margin-right: 0;\n}\n.s-module__step--NTv_v .s-module__stepBar--cfnQo.s-module__actived--M52kL {\n  border-radius: 10px 0px 0px 10px;\n  background: var(--main-green);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationConfigPage/s.module.less"],"names":[],"mappings":"AAAA;EACI,sCAAA;AACJ;AAEA;EACI,aAAA;EACA,eAAA;EACA,YAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;AAAJ;AANA;EASQ,aAAA;EACA,OAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,oCAAA;AAAR;AAEQ;EACI,kBAAA;AAAZ;AAEY;EACI,eAAA;AAAhB;AAIQ;EACI,gCAAA;EACA,6BAAA;AAFZ","sourcesContent":[".wrap {\n    background-color: var(--main-bg-color);\n}\n\n.step {\n    display: flex;\n    position: fixed;\n    bottom: 76px;\n    left: 0;\n    width: 100%;\n    z-index: 80;\n\n    .stepBar {\n        display: flex;\n        flex: 1;\n        flex-direction: row;\n        height: 4px;\n        border-radius: 10px;\n        background: var(--gray-300, #D1D5DB);\n\n        &.wrapBar {\n            margin-right: 16px;\n\n            &:last-child {\n                margin-right: 0;\n            }\n        }\n\n        &.actived {\n            border-radius: 10px 0px 0px 10px;\n            background: var(--main-green);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--xmM_f",
	"step": "s-module__step--NTv_v",
	"stepBar": "s-module__stepBar--cfnQo",
	"wrapBar": "s-module__wrapBar--rPYoQ",
	"actived": "s-module__actived--M52kL"
};
export default ___CSS_LOADER_EXPORT___;
