// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s-module__wrap--VfQ48 .s-module__textarea--bWt9m {\n  margin-bottom: 8px;\n}\n.s-module__wrap--VfQ48 p {\n  margin-bottom: 8px;\n}\n.s-module__wrap--VfQ48 .s-module__btns--mANhS button {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/operation/ChannelWithdrawDetailPage/components/Notes/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,kBAAA;AAAR;AAFA;EAKQ,kBAAA;AAAR;AALA;EASY,iBAAA;AADZ","sourcesContent":[".wrap {\n    .textarea {\n        margin-bottom: 8px;\n    }\n    p {\n        margin-bottom: 8px;\n    }\n    .btns {\n        button {\n            margin-right: 8px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "s-module__wrap--VfQ48",
	"textarea": "s-module__textarea--bWt9m",
	"btns": "s-module__btns--mANhS"
};
export default ___CSS_LOADER_EXPORT___;
