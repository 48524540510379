import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Alert, Button, Checkbox, DatePicker, Form, Input, Spin, Tooltip, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IProvderAgreementSignInput, IProvderAgreementSignResponse } from 'types/provider';
import dayjs from 'dayjs';
import { TSubscriptionType } from 'types/subscription';
import { getProviderAgreementInfo, signProviderAgreement } from 'api/provider';
import Logo from 'assets/logo/kiwihealth.png';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { Email } from 'constants/contact';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { getUrlParam } from 'utils/common';
import FormValidationHelper from 'utils/validation';

const Agreement = () => {
    const navigate = useNavigate();
    const [agreementForm] = Form.useForm<IProvderAgreementSignInput>();
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const [checked, setChecked] = useState(false);
    const [showCheckTip, setShowCheckTip] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [address, setAddress] = useState('');
    const [getInfoLoading, setGetInfoLoading] = useState(true);
    const [signLoading, setSignLoading] = useState(false);
    const [agreementData, setAgreementData] = useState<IProvderAgreementSignResponse>();
    const [showPaySuccessTip, setShowPaySuccessTip] = useState(true);
    const planType = getUrlParam('planType');

    const getAgreementInfo = useCallback(async (type: TSubscriptionType) => {
        setGetInfoLoading(true);
        try {
            const res = await getProviderAgreementInfo(type);

            if (res && !res.error) {
                setAgreementData(res.data?.data);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setGetInfoLoading(false);
    }, []);

    useEffect(() => {
        if (planType === TSubscriptionType.KLARITY_PLUS || planType === TSubscriptionType.KLARITY_PRO) {
            getAgreementInfo(planType);
        } else {
            navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
        }
    }, [getAgreementInfo, homeInfo, navigate, planType]);

    useEffect(() => {
        agreementForm.setFieldsValue({
            contractSignDate: dayjs(),
        });
    }, [agreementForm]);

    const handleCheckBoxChange = useCallback((e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);
    const handleDateChange = useCallback((e: any, dateString: string) => {
        setDate(dateString);
    }, []);
    const handleAddressChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    }, []);

    const handleAgreementSubmit = useCallback(async (data: IProvderAgreementSignInput) => {
        setSignLoading(true);
        try {
            const res = await signProviderAgreement(data);

            if (res && !res.error) {
                navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSignLoading(false);
    }, [navigate]);

    const handleFormFinish = useCallback(() => {
        if (!checked) {
            setShowCheckTip(true);
        } else {
            setShowCheckTip(false);
            agreementForm.validateFields().then((formData: IProvderAgreementSignInput) => {
                handleAgreementSubmit({
                    ...formData,
                    contractSignDate: formData.contractSignDate ? dayjs(formData.contractSignDate).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY'),
                    type: planType as TSubscriptionType,
                });
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [agreementForm, checked, handleAgreementSubmit, planType]);

    const contractUrl = agreementData?.contractUrl || '';

    if (getInfoLoading || loadingHome) {
        return <Spin spinning />;
    }

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
            </div>
            {
                showPaySuccessTip &&
                <div className={s.tip}>
                    <div className={s.tipInner}>
                        <div className={s.tipText}>🎉  Payment process successfully. Please review the agreement below to proceed.</div>
                        <div className={s.closeIcon} onClick={() => setShowPaySuccessTip(false)} />
                    </div>
                </div>
            }
            <div className={s.title}>Confirm your agreement: Secure Your Partnership with Kiwi</div>
            <div className={s.iframeWrap}>
                <iframe className={s.iframe} title="overview" src={contractUrl} />
                {/* <iframe className={s.iframe} title="overview" src={`https://docs.google.com/viewer?url=${contractUrl}&embedded=true`} /> */}
            </div>
            <div className={s.confirmBox}>
                <Checkbox checked={checked} className={s.checkbox} onChange={handleCheckBoxChange}>By checking this box, I agree to the terms outlined in the agreement.</Checkbox>
                {!checked && showCheckTip && <Alert className={s.alertMessage} message="Please accept the agreement to proceed to the next step." type="error" showIcon />}
                <Form
                    form={agreementForm}
                    id="agreementForm"
                    layout="vertical"
                >
                    <div className={s.foromItems}>
                        <div className={s.formItem}>
                            <Form.Item
                                label="Signature"
                                name="contractSignName"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                ]}
                            >
                                <Input style={{ width: '100%' }} type="text" onChange={handleNameChange} placeholder="Type your name" />
                            </Form.Item>
                            <div className={s.signatureText}>{name}</div>
                        </div>
                        <div className={`${s.formItem} ${s.fromItemDate}`}>
                            <Form.Item
                                label="Today’s date"
                                name="contractSignDate"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" disabledDate={(current) => current && current > dayjs().endOf('day')} onChange={handleDateChange} />
                            </Form.Item>
                            <div className={s.signatureText}>{date}</div>
                        </div>
                        <div className={`${s.formItem} ${s.formItemAddress}`}>
                            <Form.Item
                                label={
                                    <div className={s.formLabel}>Notice address
                                        <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#FFFFFF" title={<div className={s.tipText}>Enter your official address to receive legal documents and critical updates for your practice and contract obligations.</div>}>
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                name="contractSignAddress"
                                style={{ marginBottom: '4px' }}
                                validateFirst
                                rules={[
                                    { required: true, message: 'This is a required field' },
                                    // FormValidationHelper.validateNonChinese('Please do not enter Chinese'),
                                ]}
                            >
                                <Input style={{ width: '100%' }} type="text" placeholder="Type your address" onChange={handleAddressChange} />
                            </Form.Item>
                            <div className={s.signatureText}>{address}</div>
                        </div>
                    </div>
                </Form>
            </div>
            <div className={s.footer}>
                <div className={s.leftPart}>
                    <div className={s.helpLink}>
                        <a href={`mailto:${Email.klarity_provider_support}`}>Need Help?</a>
                    </div>
                </div>
                <div className={s.rightPart}>
                    <Button
                        type="primary"
                        loading={signLoading}
                        onClick={handleFormFinish}
                    >Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Agreement;
